@import "~/styles/mixins.scss";
.wrapper {
	.content {
		display: grid;
		grid-template-columns: repeat(2, minmax(50px, 1fr));
		grid-template-rows: 2fr;

		@include tablet {
			grid-template-columns: repeat(4, minmax(100px, 1fr));
			column-gap: 16px;
		}

		.product {
			grid-column: span 1;
			grid-row: span 1;
		}

		.imageWrapper {
			grid-column: span 2;
			grid-row: span 2;

			&.alignRight {
				@include tablet {
					order: 2;
				}
			}

			.img {
				width: 100%;
			}
		}

		.productsAlignLeft {
			order: 2;
		}

		.productList {
			grid-column: span 1;
			grid-row: span 1;

			.product {
				grid-column: span 1;
				grid-row: span 1;
			}
		}

		.bigProduct {
			padding: 0;

			@include small-desktop {
				padding: 32px 24px;
			}
		}
	}
}
