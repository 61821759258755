@import "~/styles/mixins.scss";
.wrapper {
	grid-column: span 2;
	grid-row: span 2;
	margin-top: 24px;

	@include tablet {
		margin-top: 0;
	}

	.carousel {
		height: auto;

		.slide {
			width: calc(50% - 8px);
			margin-right: 0;

			@include tablet {
				grid-column: span 1;
				grid-row: span 2;
			}
		}

		.swiper {
			padding-bottom: 30px;
		}

		.skipContent {
			padding: 0;

			li:before {
				display: none;
			}
		}
	}
}
