@import "~/styles/mixins.scss";
.wrapper {
	grid-column: span 2;
	grid-row: span 1;
	margin-top: 24px;

	@include tablet {
		margin-top: 0;
	}

	.skipContent {
		padding: 0;

		li:before {
			display: none;
		}
	}

	.carousel {
		height: auto;

		.slide {
			width: calc(50% - 4px);
			margin-right: 0;
		}
	}
}
