@import "~/styles/mixins.scss";
.wrapper {
	.carousel {
		height: auto;

		.swiper {
			padding-bottom: 30px;
		}

		.skipContent {
			padding: 0;

			li:before {
				display: none;
			}
		}
	}
}
