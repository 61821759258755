@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: $white;
	transition: border .2s ease;
	position: relative;
	border: 2px solid $white;
	grid-column: span 1;
	grid-row: span 1;

	@include tablet {
		padding: 16px 8px;

		&:hover {
			border: 2px solid $navy;
		}
	}

	@include small-desktop {
		padding: 25px;
	}

	@include desktop {
		padding: 32px 24px;
	}

	.topWrapper {
		position: relative;
	}

	.button {
		line-height: 14px;
	}

	.wishList {
		z-index: 1;
		position: absolute;
		top: 8px;
		right: 8px;
	}

	.imageWrapper {
		:nth-child(2) {
			display: block;
			width: fit-content;
			margin-top: -1px;
		}
	}
}
