@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	order: 3;
	min-height: 15px;
	min-width: 15px;
	justify-content: center;
	align-items: center;
	padding-top: 20px;

	@include tablet {
		order: 2;
		padding-top: 0;
		justify-content: normal;
	}

	.content {
		display: none;

		&.visible {
			display: flex;
		}

		.label {
			margin-right: 6px;
		}

		.version {
			color: $green;
		}
	}
}
