@import "~/styles/mixins.scss";
.wrapper {
	margin: 0;

	a {
		text-decoration: underline;

		&:hover {
			color: $green-dark;
		}
	}

	p {
		margin-top: 16px;
	}
}
