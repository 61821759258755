@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	position: relative;

	.image {
		width: 100%;
		object-fit: contain;
		object-position: 0;
		margin-top: 32px;
		display: block;
	}
}
