@import "~/styles/mixins.scss";
.wrapper {
	margin: 24px 0;
	display: flex;
	flex-direction: column;

	.title {
		margin: 0;
	}

	.productList {
		display: flex;
		flex-wrap: wrap;
		margin-top: 30px;

		.product {
			width: calc(50% - 8px);
			margin: 0 16px 40px 0;

			@include tablet {
				width: calc(25% - 12px);

				&:nth-child(4n) {
					margin-right: 0;
				}
			}

			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
}
