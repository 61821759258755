@import "~/styles/mixins.scss";
.wrapper {
	border-top: 1px solid $white10;
	min-height: 99px;
	padding: 24px 16px;
	display: flex;
	flex-direction: column;

	@include small-desktop {
		height: 70px;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	@include large-desktop {
		padding: 24px 0;
	}

	.copyright {
		font-size: 10px;
		line-height: 13px;
		color: $white50;
		order: 2;
		margin-top: 24px;

		@include small-desktop {
			margin-top: 0;
			font-size: 12px;
			order: 1;
		}
	}

	.links {
		order: 1;
		font-size: 12px;
		display: flex;
		justify-content: space-between;

		@include mobile {
			justify-content: flex-start;
			gap: 16px;
		}

		@include small-desktop {
			order: 2;
			gap: 48px;
		}

		.label {
			white-space: nowrap;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
